import Cursor from './cursor';

var boxes = document.querySelectorAll(".box");
var posters = document.querySelectorAll(".poster");
var footer = document.querySelector(".footer");
var body = document.querySelector("body");
var intro = document.querySelector(".intro__banner");
var introBackground = document.querySelector(".intro__background");

var openFooterBtn = document.querySelector(".button--info");
var closeFooterBtn = document.querySelector(".button--close");

var ratio = 0.69956;
var colors = [];
var currentColor = 0;

var posterHeight,
	ratioPosterViewport,
	spaceToTop,
	swipeStart,
	cursor,
	introInterval;

var spaceTopTrigger = 0;
var spaceBottomTrigger = 0;

var currentEventNumber = -1;
var isMobile = false;
var isFooterOpened = false;
var footerTimeout;

openFooterBtn.addEventListener("click",function(){
	openFooter();
});

closeFooterBtn.addEventListener("click",function(){
	closeFooter();
});


window.addEventListener("load", function() {
	init();
});

function init(){

	cursor = new Cursor();
	cursor.bindClickable()

	if ( browserSupportsPositionSticky() ) {
		window.addEventListener("scroll", function(){
			_onScroll();
		});

		window.addEventListener("keydown", function(e){
			_onKeyDown(e);
		});

		initBoxNavigation();
	} else {
		body.classList.add('no-sticky');
	}

	window.addEventListener('wheel', function(event)
	{
		if (event.deltaY < -50 && isFooterOpened && footer.scrollTop <= 0) {
			closeFooter();
		}
	});


	window.addEventListener('resize', function() {
		_onResize();
	});

	initMobileTextBoxes();
	initIntroCycle();

	setScrollToOnAnchorLinks();

	resize();

	setTimeout(
		function() {
			window.scrollTo(0,0);
		}
	, 100);

}

function browserSupportsPositionSticky() {
	var prop = 'position:';
	var value = 'sticky';
	var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

	var el = document.createElement('a');
	var mStyle = el.style;
	mStyle.cssText = prop + prefixes.join(value + ';' + prop).slice(0, - prop.length);

	return mStyle.position.indexOf(value) !== -1;
}

function initMobileTextBoxes() {
	for (let i = 0; i < posters.length; i++) {
		const textBox = boxes[i].querySelector('.box__info').cloneNode(true);
		posters[i].appendChild(textBox);
	}
}

function initIntroCycle() {
	for (let i = 0; i < posters.length; i++) {
		let colorClassName = posters[i].querySelector('.poster__visual').classList.item(1);
		if(colorClassName!=="bg--red"){
			colors.push(colorClassName);
		}else{
		}
		
	}

	console.log(colors);

	introInterval = setInterval(cycleIntroBackground, 1000);
}

function cycleIntroBackground() {
	if (!isScrolledIntoView(intro) || !document.hasFocus()) return;
	console.log('cycle background');
	let bkg = document.createElement('div');
	bkg.className = 'bg ' + colors[currentColor];
	introBackground.appendChild(bkg);

	if (currentColor < colors.length - 1) currentColor++;
	else currentColor = 0;

	if (introBackground.childElementCount > 2) {
		introBackground.removeChild(introBackground.firstChild);
	}
}

function isScrolledIntoView(el)
{
	const rect = el.getBoundingClientRect();
	const elemTop = rect.top;
	const elemBottom = rect.bottom;

	// Partially visible elements return true:
	const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
	return isVisible;

}

function resize() {
	isMobile = window.matchMedia("(max-width: 600px)").matches;

	let vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	posterHeight = window.innerWidth * 1.42946058;
	ratioPosterViewport = window.innerHeight / posterHeight;
	spaceToTop = window.innerHeight;

	spaceTopTrigger = window.innerHeight * 0.4;
	spaceBottomTrigger = window.innerHeight * 0.4;

}

function _onResize() {
	resize();
}


function _onKeyDown(e) {
	console.log("currentEventNumber: " + currentEventNumber);

	switch (e.keyCode) {
		case 38: // up
			if (isFooterOpened  === true) {
				closeFooter();
				scrollToBox(boxes.length - 1, 50);
			} else {
				scrollToBox(currentEventNumber - 1, 50);
			}
		break;

		case 40: // down

			if (currentEventNumber >= boxes.length - 1) {
				openFooter();
			} else {
				scrollToBox(currentEventNumber + 1, -30);
			}

		break;
	}

}

function _onScroll() {

	//console.log('on scroll');

	let pos = -1;

	if (isMobile) {
		for (let i = 0; i < posters.length; i++){
			let bounding = posters[i].getBoundingClientRect();
			if (bounding.bottom >= 0 && bounding.top <= window.innerHeight *0.4 ){
				pos = i;
			}
		}
	} else {
		for (let i = 0; i < boxes.length; i++){
			let bounding = boxes[i].getBoundingClientRect();

			if (bounding.top <= spaceTopTrigger && bounding.bottom >= spaceBottomTrigger) {
				pos = i;
			}
		}
	}

	if (pos != currentEventNumber) changePosition(pos);

	if (!isMobile) {
		if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight * 0.99) {
			if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
				openFooter(false);
			} else {
				closeFooter();
			}
		}
	}
}

function initBoxNavigation(){
	for (let h = 0; h < boxes.length; h++) {
		boxes[h].addEventListener("click", function() {
			scrollToBox(h);
		})
	}
}

function setScrollToOnAnchorLinks() {
	let anchors = document.querySelectorAll('a[href^="#"]');

	for (let i = 0; i < anchors.length; i++) {
		anchors[i].addEventListener('click', function (e) {
			e.preventDefault();
			closeFooter();

			let anchor = this.getAttribute('href');
			document.querySelector(anchor).scrollIntoView({
				behavior: 'smooth'
			});

		});
	}
}

function scrollToBox(idx, padding = 0) {
	if (idx < 0) {
		window.scrollTo(0,0);
	} else {
		if (isMobile)
			window.scrollTo(0, posters[idx].getBoundingClientRect().top + window.scrollY);
		else
			window.scrollTo(0, (idx * (window.innerHeight * 0.8) + spaceToTop + padding));
	}
}

function openFooter() {
	if (isFooterOpened) return;

	footer.classList.add("footer--open");
	console.log("Open footer");

	if (footerTimeout) clearTimeout(footerTimeout);

	body.classList.add("noscroll");

	footerTimeout = setTimeout(function(){
		isFooterOpened = true; }
	, 1000);
}

function closeFooter() {
	if (!isFooterOpened) return;

	footer.classList.remove("footer--open");
	console.log("Close footer");

	body.classList.remove("noscroll");

	if (footerTimeout) clearTimeout(footerTimeout);
	footerTimeout = setTimeout(function(){ isFooterOpened = false }, 1000);
}

function clearPosters(){
	console.log("clear posters");
	for (let i = 0; i < posters.length; i++) {
		boxes[i].classList.remove("box--active");
		posters[i].classList.remove("poster--active");
	}
}

function changePosition(eventNumber) {

	console.log('change position to : ' + eventNumber);

	if (isMobile) {
		clearPosters();
	} else {
		setTimeout(function(){ clearPosters(); }, 300);
	}

	if (eventNumber >= 0) {
		if (isMobile) {
			boxes[eventNumber].classList.add("box--active");
			posters[eventNumber].classList.add("poster--active");
		} else {
			setTimeout(function(){
				boxes[eventNumber].classList.add("box--active");
				posters[eventNumber].classList.add("poster--active");
			}, 300);
		}
	}
	if (eventNumber >= 3) {
		posters[0].style.opacity="0";
	}else{
		posters[0].style.opacity="1";
	}

	currentEventNumber = eventNumber;

}




