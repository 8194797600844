export default class Cursor {
	constructor() {
		this.$cursor = document.querySelector('.js-cursor')
		this.x = 0
		this.y = 0

		this.$cursor.classList.remove('invisible')

		document.addEventListener('mousemove', this.onMove.bind(this))
	}

	onMove(e) {
		const x = e.clientX
		const y = e.clientY

		this.x = x
		this.y = y
		this.$cursor.style.transform = `translate3d(${this.x}px, ${this.y}px, 0)`
	}

	bindClickable() {
		const $elements = document.querySelectorAll('.button, .link, .intro__banner svg path')
		for (let i = 0; i < $elements.length; i++) {
			this.addClassOnHover($elements[i], 'is-hover')

			$elements[i].addEventListener('click', () => {
				this.$cursor.classList.add('is-click')
				setTimeout(() => this.$cursor.classList.remove('is-click'), 500)
			})
		}

	}

	addClassOnHover(element, className) {
		element.addEventListener('mouseenter', () => this.$cursor.classList.add(className))
		element.addEventListener('mouseleave', () => this.$cursor.classList.remove(className))
	}
}
